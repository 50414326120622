import React from 'react';
import { TextField, Link, Typography, Button, Container, Grid, Hidden } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import useStyles from './Index.styles';
import IndexViewData from './Index.data';
import birdIndex from '../../../../../content/assets/birdIndex.svg';
import QuoteCommon from '../../../Quote/QuoteCommon';
import AppStoreIcon from '../../../../images/appstore.svg';
import GooglePlayIcon from '../../../../images/googleplay.svg';

const IndexView = ({ children }) => {
  const { title, subtitle, urls, elements } = IndexViewData;
  const { uaf, bancoCentral, prevencionDelito } = urls;
  const { quote, regulations, transferButton } = elements;
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="inicio">
        <Container className={classes.container}>
          <Grid className={classes.gridContainer} container>
            <Grid className={classes.firstSubSection} item xs={12} md={5}>
              <Grid className={classes.firstSubSectionTitle} item xs={12}>
                <h1 className={classes.title}>
                  La ruta perfecta para <span>transferir dinero al extranjero</span>
                </h1>
              </Grid>
              <Hidden smDown>
                <Grid className={classes.firstSubSectionSubtitle} item xs={12}>
                  <h2>{subtitle}</h2>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid className={classes.firstSubSectionSubtitle} item xs={12}>
                  <h2>
                    <strong>Envíos de dinero</strong> <br />
                    simples, transparentes y 100% online.
                  </h2>
                </Grid>
              </Hidden>
              {/* <Hidden smDown>
                <Grid item xs={12}>
                  <Link className={classes.howWorks} href="#tres-pasos">
                    ¿Cómo funciona?
                  </Link>
                </Grid>
              </Hidden> */}
              <Hidden smDown>
                <Grid container item xs={12} alignItems="center" className={classes.appContainer}>
                  <Grid item>
                    <Typography variant="body2" className={classes.availableText}>
                      También disponible en
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://apps.apple.com/us/app/currencybird-env%C3%ADos-de-dinero/id6503993314"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={AppStoreIcon}
                        alt="Disponible en App Store"
                        className={classes.storeIcon}
                      />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://play.google.com/store/apps/details?id=cl.currencybird.cbmobileapp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={GooglePlayIcon}
                        alt="Disponible en Google Play"
                        className={classes.storeIcon}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                  className={classes.appLinksContainer}
                >
                  <a
                    href="https://apps.apple.com/us/app/currencybird-env%C3%ADos-de-dinero/id6503993314"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={AppStoreIcon}
                      alt="Disponible en App Store"
                      className={classes.storeIcon}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=cl.currencybird.cbmobileapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={GooglePlayIcon}
                      alt="Disponible en Google Play"
                      className={classes.storeIcon}
                    />
                  </a>
                </Grid>
              </Hidden>
            </Grid>
            <Hidden smDown>
              <Grid container item xs={12} md={7}>
                {/*TODO: add optimized image*/}
                {children}
                {/*<img className={classes.birdIndex} src={birdIndex} />*/}
              </Grid>
            </Hidden>
            <Grid className={classes.secondSubSection} container>
              <div className={classes.quote} id={quote.name} aria-labelledby={quote.label}>
                <QuoteCommon></QuoteCommon>
              </div>

              <Grid className={classes.transferGroup} item>
                <div className={classes.transfer}>
                  <Button
                    href="/register/"
                    onClick={(e) => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'buttonClick',
                        category: 'Personas',
                        action: 'click_transfiere',
                        label: 'Cotizador, click en boton transfiere',
                      });
                    }}
                    className={classes.transferButton}
                  >
                    {transferButton.label}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.regulations}>
            <div className={classes.regulationsText}>{regulations.label}</div>
            <Grid container item alignItems="center" justifyContent="flex-start">
              <Grid item xs={12} md={6} lg={6}>
                <span className={classes.regulationsLinks}>{bancoCentral.label}</span>
              </Grid>
              <Grid item xs={12} md={2}>
                <span className={classes.regulationsLinks}>{prevencionDelito.label}</span>
              </Grid>
              <Grid item xs={12} md={2}>
                <span className={classes.regulationsLinks}>{uaf.label}</span>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexView;
